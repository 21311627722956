import React, {useState} from "react";
import HeaderW from "../../widgets/header/HeaderW";
import logo from './img/logotip.png'
import {useDispatch} from "react-redux";
import {setAuthorizationAction} from "../../../store/authorizationReducer";
import {IndexedDB} from "../../../asinc/indexeddb";
import {send_log_data} from "../../../asinc/send_log_data";

const AuthorizationPage = () => {

    const [values, setValues] = useState({'login': '', 'password': ''});
    const [loginError, setLoginError] = useState(false);
    const dispatch = useDispatch()
    // Обновляем состояние при вводе пользователем данных
    const onChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    // Реализация запроса авторизации
    const sendRequest = event => {
        (async () => {
            let userA = '';
            let userId = '';
            try {
                fetch('https://prorab.domovedov.com/api/auch.php', {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({
                        login: values.login,
                        password: values.password,
                        action: 'authorization'
                    }), // Тело запроса в JSON-формате
                    headers: {
                        // Добавляем необходимые заголовки
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {

                        if (data.data.token === 0) {
                            setLoginError(true);
                            setTimeout(function () {
                                setLoginError(false);
                            }, 2000);
                        } else {
                            setLoginError(false);
                            const jwtToken = data.data.token;
                            const jwt = data.data;
                            userA = data.data.name;
                            userId = data.data.id_user;
                            // Логируем вход в приложение
                            send_log_data({
                                uid: userId,
                                uname: userA,
                                action: 'Авторизация пользователя: ' + userA,
                                request: JSON.stringify({
                                    login: values.login,
                                    password: values.password,
                                    action: 'authorization'
                                })
                            })

                            if (jwtToken !== 0) {
                                // Дополнительные действия с полученным JWT токеном
                                localStorage.setItem('token', jwtToken);
                                localStorage.setItem('user', userA);
                                localStorage.setItem('id_user', userId);
                                dispatch(setAuthorizationAction(jwt));
                            }
                            IndexedDB().then(db => {
                                // Запустим транзакцию базы данных и получите хранилище объектов Notes
                                let tx = db.transaction(['user'], 'readwrite');
                                let store = tx.objectStore('user');
                                // очищаем хранилище.
                                store.clear();
                                // Ожидаем завершения транзакции базы данных
                                tx.oncomplete = () => {
                                    console.log('Хранилище документов очищено')
                                }
                                tx.onerror = (event) => {
                                    alert('Ошибка очистки хранилища документов ' + event.target.errorCode);
                                }
                            })
                            IndexedDB().then(db => {
                                // Запустим транзакцию базы данных и получите хранилище объектов documents
                                let tx = db.transaction(['user'], 'readwrite');
                                let store = tx.objectStore('user');
                                // Добавим авторизованного пользователя в хранилище
                                store.add(jwt);
                                // Ожидаем завершения транзакции базы данных
                                tx.oncomplete = () => {
                                    console.log('Документ записан');
                                }
                                tx.onerror = (event) => {
                                    alert('Ошибка записи документа ' + event.target.errorCode);
                                }
                            })
                        }
                    })
            } catch (error) {
                console.error(error);
            }
        })();
    }

    return (
        <>
            <HeaderW filter={false} title="Авторизация"/>
            <div id="appCapsule" className="pt-0">
                <div className="login-form mt-5">
                    <div className="section">
                        <img src={logo} alt="image" className="form-image"/>
                    </div>
                    <div className="section title_form_block mt-5">
                        <h1>Мобильный прораб</h1>
                        <h4>Авторизуйтесь</h4>
                    </div>
                    <div className="section mt-1 mb-5">
                        <form>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email1"
                                        placeholder="Логин"
                                        value={values.login}
                                        name="login"
                                        onChange={onChange}
                                    />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password1"
                                        placeholder="Пароль"
                                        autoComplete="off"
                                        value={values.password}
                                        name="password"
                                        onChange={onChange}
                                    />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            {loginError &&
                                <div className="form-group boxed">
                                    <h3 className="error_notify auchTextError">Неверный логин или пароль</h3>
                                </div>
                            }

                            <div className="form-button-group">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block btn-lg"
                                    onClick={sendRequest}
                                >Авторизоваться
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AuthorizationPage
