import {IndexedDB} from "./indexeddb";
import {send_log_data} from "./send_log_data";
export const fetchGet1CData = (RequestParameters) => {
    return new Promise((resolve, reject) => {
        try {
            let urlReq = '';
            // CURLINFO_HTTP_CODE: 403 Res: Ошибка в 1с
            // CURLINFO_HTTP_CODE: 200 Res: ОК

            if(RequestParameters.test) {
                urlReq = 'https://prorab.domovedov.com/api/get_1c.json';
            } else {
                urlReq = 'https://prorab.domovedov.com/api/get_1c.php';
            }

            fetch( // document.php
                urlReq, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({
                        dateFrom: RequestParameters.startData,
                        dateTo: RequestParameters.endData,
                        id: RequestParameters.token,
                        test: RequestParameters.test
                    }), // Тело запроса в JSON-формате
                    headers: {
                        // Добавляем необходимые заголовки
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                .then(response => response.json())
                .then(json => {

                    send_log_data({
                        uid: Number(localStorage.getItem('id_user')),
                        uname: localStorage.getItem('user'),
                        action: 'Запрос на получение данных из 1С (успешный)',
                        request: JSON.stringify(json)
                    })

                    IndexedDB().then(db => {
                        // Запустим транзакцию базы данных и получите хранилище объектов Notes
                        let tx = db.transaction(['documents'], 'readwrite');
                        let store = tx.objectStore('documents');
                        // очищаем хранилище.
                        store.clear();
                        // Ожидаем завершения транзакции базы данных
                        tx.oncomplete = () => {
                            console.log('Хранилище документов очищено')
                        }
                        tx.onerror = (event) => {
                            alert('Ошибка очистки хранилища документов ' + event.target.errorCode);
                        }
                    })

                    json.forEach(document => {
                        // Запишем в indexDB полученные данные
                        IndexedDB().then(db => {
                            // Запустим транзакцию базы данных и получите хранилище объектов documents
                            let tx = db.transaction(['documents'], 'readwrite');
                            let store = tx.objectStore('documents');
                            // Добавим авторизованного пользователя в хранилище
                            store.add(document);
                            // Ожидаем завершения транзакции базы данных
                            tx.oncomplete = () => {
                                console.log('Документ записан')
                            }
                            tx.onerror = (event) => {
                                alert('Ошибка записи документа ' + event.target.errorCode);
                            }
                        })
                    })

                    resolve(json);
                })
                .catch(e => {
                    send_log_data({
                        uid: localStorage.getItem('id_user'),
                        uname: localStorage.getItem('user'),
                        action: 'Запрос на получение данных из 1С (неудачный)',
                        request: 'нет данных'
                    })
                    reject([{}])
                });
        } catch (err) {
            console.log(err.message)
        }
    })
}
