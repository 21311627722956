import React, {useEffect, useLayoutEffect, useState} from "react";
import HeaderW from "../../widgets/header/HeaderW";
import {useDispatch, useSelector} from "react-redux";
import {addWarehousesAction, removeWarehousesAction} from "../../../store/warehousesReducer";
import WarehousesBlock from "../../widgets/warehouses/WarehousesBlock";
import {IndexedDB} from "../../../asinc/indexeddb";

const WarehousesPage = () => {
    const dispatch = useDispatch();
    let newWarehouses = [];

    newWarehouses.p = [];
    newWarehouses.l = [];
    newWarehouses.o = [];

    const authorizationStore = useSelector(state => state.authorizationReducer.authorization);
    const warehousesStore = useSelector(state => state.warehouses.list);

    const [screenData, setScreenData] = useState([]);

    useLayoutEffect(() => {
        if (navigator.onLine) {
            // Если есть сеть, то подтянем обновлённые данные
            fetch(
                'https://prorab.domovedov.com/api/get_warehouses.php', {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({
                        id: authorizationStore.token,
                    }), // Тело запроса в JSON-формате
                    headers: {
                        // Добавляем необходимые заголовки
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                .then(response => response.json())
                .then(json => {
                    // очистим хранилище от старых данных
                    dispatch(removeWarehousesAction());
                    // очистим хранилище от старых данных
                    IndexedDB().then(db => {
                        // Запустим транзакцию базы данных и получите хранилище объектов Notes
                        let tx = db.transaction(['warehouses'], 'readwrite');
                        let store = tx.objectStore('warehouses');
                        // очищаем хранилище.
                        store.clear();
                        // Ожидаем завершения транзакции базы данных
                        tx.oncomplete = () => {
                            console.log('Хранилище документов очищено')
                        }
                        tx.onerror = (event) => {
                            alert('Ошибка очистки хранилища документов ' + event.target.errorCode);
                        }
                    });
                    // Получив Json запишем его в хранилище
                    json.stocks.map(warehouseData => {
                        // Запишем в indexDB полученные данные
                        IndexedDB().then(db => {
                            // Запустим транзакцию базы данных и получите хранилище объектов documents
                            let tx = db.transaction(['warehouses'], 'readwrite');
                            let store = tx.objectStore('warehouses');
                            // Добавим авторизованного пользователя в хранилище
                            store.add(warehouseData);
                            // Ожидаем завершения транзакции базы данных
                            tx.oncomplete = () => {
                                console.log('Документ записан')
                            }
                            tx.onerror = (event) => {
                                alert('Ошибка записи документа ' + event.target.errorCode);
                            }
                        })
                    });
                    // Получив Json запишем его в хранилище
                    json.stocks.map(warehouses => {
                        dispatch(addWarehousesAction(warehouses));
                    })
                })
        } else {
            // Если текущее хранилище лише пусто, заполним его из локальной БД
            if (warehousesStore.length === 0) {
                // Получим записи из хранилища
                IndexedDB().then(db => {
                    // Запустим транзакцию базы данных и получите хранилище объектов documents
                    let tx = db.transaction(['warehouses'], 'readonly');
                    let store = tx.objectStore('warehouses');
                    // Создать запрос курсора
                    let req = store.openCursor();
                    let allDocument = [];
                    req.onsuccess = (event) => {
                        let cursor = event.target.result;
                        if (cursor != null) {
                            allDocument.push(cursor.value);
                            cursor.continue();
                        } else {
                            dispatch(addWarehousesAction(allDocument));
                        }
                    }
                    // Ожидаем завершения транзакции базы данных
                    tx.oncomplete = () => {
                        console.info('Синхронизация завершена.');
                    }
                    tx.onerror = (event) => {
                        console.error('Ошибка записи документов: ', event.target.errorCode);
                    }
                })
            }
        }
    }, []);

    useEffect(() => {

        let mainWarehouses = [];
        let optWarehouses = [];
        let otherWarehouses = [];

        warehousesStore.forEach((warehouses, index) => {
            if (
                warehouses.nominal === true &&
                warehouses.wholesale === false
            ) {
                // Личный склад
                mainWarehouses.push(warehouses)
            }

            if (
                warehouses.nominal === false &&
                warehouses.wholesale === true
            ) {
                // Оптовый склад
                optWarehouses.push(warehouses)
            }

            if (
                warehouses.nominal === false &&
                warehouses.wholesale === false
            ) {
                // Приобъектный склад
                otherWarehouses.push(warehouses);
            }
        });

        if (otherWarehouses.length !== 0) {
            // Приобъектный склад
            newWarehouses.p.push(otherWarehouses)
        }

        if (mainWarehouses.length !== 0) {
            // Личный склад
            newWarehouses.l.push(mainWarehouses)
        }

        if (optWarehouses.length !== 0) {
            // Оптовый склад
            newWarehouses.o.push(optWarehouses)
        }



        setScreenData(newWarehouses)


    }, [warehousesStore])

    let titleCode = "";

    return(
        <>
            <HeaderW filter={true} title="Склады"/>
            <div className="screenBlock">
                {Object.keys(screenData).map(typeBlock => (
                    <div key={typeBlock}>
                        {(typeBlock === 'p' && screenData[typeBlock].length > 0) && <div className="NominalSet top-m25">Объектные склады</div>}
                        {(typeBlock === 'l' && screenData[typeBlock].length > 0) && <div className="NominalSet">Личный склад</div>}
                        {(typeBlock === 'o' && screenData[typeBlock].length > 0) && <div className="NominalSet">Оптовый склад</div>}

                        {typeBlock !== 'l' && typeBlock !== 'o' && typeBlock !== 'p' && <div className="NominalSet top-m25">Склады</div>}
                        {screenData[typeBlock].map((type, index) => (
                            type.map((warehouse, key) => (
                                <WarehousesBlock link="/warehouses/" data={warehouse} key={key}/>
                            ))
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}

export default WarehousesPage;
