import {IndexedDB} from "./indexeddb";
import {send_log_data} from "./send_log_data";

const periodicSync = () =>
{
    let interval_id = localStorage.getItem('timer');

    clearInterval(interval_id);

        let timer_id = setInterval(() => {
            if (localStorage.getItem('token')) {
                //console.info('Периодическая синхронизация каждые 5 sec');
                if(navigator.onLine) {
                    // Получим записи с документами из таблицы online_sends
                    IndexedDB().then(db => {
                        // Запустим транзакцию базы данных и получите хранилище объектов documents
                        let tx = db.transaction(['online_sends'], 'readonly');
                        let store = tx.objectStore('online_sends');

                        // Создать запрос курсора
                        let req = store.openCursor();
                        let allDocument = [];

                        req.onsuccess = (event) => {
                            let cursor = event.target.result;
                            if (cursor != null) {
                                allDocument.push(cursor.value);
                                cursor.continue();
                            } else {
                                allDocument.map(
                                    document => {
                                        // Отправим запрос на сервер 1С
                                        const server_url = "https://prorab.domovedov.com/api/send_1c_data.php";

                                        fetch(server_url, {
                                            method: 'POST',
                                            mode: 'cors',
                                            body: JSON.stringify({type: 'prorab', data: document, id: localStorage.getItem('token')}),
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                        }).then(res => res.text())
                                            .then(respText => {
                                                if (respText === 'ОК') {
                                                    // Данные успешно отправлены и приняты на стороне 1С
                                                    // Необходимо удалить запись из списка отправленных
                                                    IndexedDB().then(db => {
                                                        // Запустим транзакцию базы данных и получите хранилище объектов documents
                                                        let tx2 = db.transaction(['online_sends'], 'readwrite');
                                                        let store = tx2.objectStore('online_sends');

                                                        // Создать запрос курсора
                                                        let req2 = store.openCursor();
                                                        let found = false; // Флаг, чтобы отслеживать, найдена ли запись для удаления

                                                        req2.onsuccess = (event) => {
                                                            let cursor2 = event.target.result;
                                                            if (cursor2) {
                                                                let document_2 = cursor2.value;
                                                                if (document.guid === document_2.guid) { // Проверяем условие на соответствие свойству
                                                                    found = true;
                                                                    cursor2.delete(); // Удаляем запись.
                                                                    // todo: Обновим статус документа, на отправленный.
                                                                    let userId = localStorage.getItem('id_user');
                                                                    let userName = localStorage.getItem('user');

                                                                    // Логируем отправку данных из приложения
                                                                    send_log_data({
                                                                        uid: userId,
                                                                        uname: userName,
                                                                        action: 'Отправлен документ',
                                                                        request: JSON.stringify({
                                                                            document: document,
                                                                            action: 'send'
                                                                        })
                                                                    })
                                                                        .then(res => {
                                                                            console.info('Отправлен документ', res);
                                                                        });
                                                                }
                                                                cursor2.continue();
                                                            }
                                                        };

                                                        // Ожидаем завершения транзакции базы данных
                                                        tx2.oncomplete = () => {
                                                            if (found) {
                                                                console.log('Запись успешно удалена');
                                                            } else {
                                                                console.log('Запись с указанным ID не найдена');
                                                            }
                                                        };

                                                        tx2.onerror = (event) => {
                                                            alert('Ошибка записи документов ' + event.target.errorCode);
                                                        };
                                                    });
                                                } else {
                                                    // Данные не были приняты, отправка не была успешной
                                                    console.info('Данные не были приняты, отправка не была успешной.');
                                                }
                                            }).catch(error => {
                                            console.error(error);
                                        })
                                    }
                                )
                            }
                        }

                        // Ожидаем завершения транзакции базы данных
                        tx.oncomplete = () => {
                            //console.info('Синхронизация завершена.');
                        }
                        tx.onerror = (event) => {
                            console.error('Ошибка записи документов: ', event.target.errorCode);
                        }
                    })
                    // Получим записи из таблицы warehouses_sends
                    IndexedDB().then(db => {
                        // Запустим транзакцию базы данных и получите хранилище объектов documents
                        let tx = db.transaction(['warehouses_sends'], 'readonly');
                        let store = tx.objectStore('warehouses_sends');

                        // Создать запрос курсора
                        let req = store.openCursor();
                        let allDocument = [];

                        req.onsuccess = (event) => {
                            let cursor = event.target.result;
                            if (cursor != null) {
                                allDocument.push(cursor.value);
                                cursor.continue();
                            } else {
                                allDocument.map(
                                    document => {
                                        // Отправим запрос на сервер 1С
                                        const server_url = "https://prorab.domovedov.com/api/send_1c_data.php";
                                        fetch(server_url, {
                                            method: 'POST',
                                            mode: 'cors',
                                            body: JSON.stringify({type: 'warehouses', data: document, id: localStorage.getItem('token')}),
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                        }).then(res => res.text())
                                            .then(respText => {
                                                if (respText === 'ОК') {
                                                    // Данные успешно отправлены и приняты на стороне 1С
                                                    // Необходимо удалить запись из списка отправленных
                                                    IndexedDB().then(db => {
                                                        // Запустим транзакцию базы данных и получите хранилище объектов documents
                                                        let tx2 = db.transaction(['warehouses_sends'], 'readwrite');
                                                        let store = tx2.objectStore('warehouses_sends');

                                                        // Создать запрос курсора
                                                        let req2 = store.openCursor();
                                                        let found = false; // Флаг, чтобы отслеживать, найдена ли запись для удаления

                                                        req2.onsuccess = (event) => {
                                                            let cursor2 = event.target.result;
                                                            if (cursor2) {
                                                                let document_2 = cursor2.value;
                                                                if (document.stocks.guid === document_2.stocks.guid) { // Проверяем условие на соответствие свойству
                                                                    found = true;
                                                                    cursor2.delete(); // Удаляем запись.
                                                                    // todo: Обновим статус документа, на отправленный.
                                                                    let userId = localStorage.getItem('id_user');
                                                                    let userName = localStorage.getItem('user');

                                                                    // Логируем отправку данных из приложения
                                                                    send_log_data({
                                                                        uid: userId,
                                                                        uname: userName,
                                                                        action: 'Отправлен документ',
                                                                        request: JSON.stringify({
                                                                            document: document,
                                                                            action: 'send'
                                                                        })
                                                                    })
                                                                        .then(res => {
                                                                            console.info('Отправлен документ', res);
                                                                        });
                                                                }
                                                                cursor2.continue();
                                                            }
                                                        };

                                                        // Ожидаем завершения транзакции базы данных
                                                        tx2.oncomplete = () => {
                                                            if (found) {
                                                                console.log('Запись успешно удалена');
                                                            } else {
                                                                console.log('Запись с указанным ID не найдена');
                                                            }
                                                        };

                                                        tx2.onerror = (event) => {
                                                            alert('Ошибка записи документов ' + event.target.errorCode);
                                                        };
                                                    });

                                                } else {
                                                    // Данные не были приняты, отправка не была успешной
                                                    console.info('Данные не были приняты, отправка не была успешной.');
                                                }
                                            }).catch(error => {
                                            console.error(error);
                                        })
                                    }
                                )
                            }
                        }

                        // Ожидаем завершения транзакции базы данных
                        tx.oncomplete = () => {
                            console.info('Синхронизация завершена.');
                        }
                        tx.onerror = (event) => {
                            console.error('Ошибка записи документов: ', event.target.errorCode);
                        }
                    })
                }
            }
        }, 5000);

    localStorage.setItem('timer', timer_id.toString());
}

export default periodicSync;
